<template>
<div style="width:70%">
      <el-table
    :data="tableData"
    style="width: 100%">
     <el-table-column
      label="标题"
      prop="title">
    </el-table-column>

    <el-table-column
      label="发布者"
      prop="author">
    </el-table-column>
    <el-table-column
      label="发布日期"
      prop="pubdata">
    </el-table-column>
     <el-table-column
      label="状态"
      prop="isdown">
    </el-table-column>
    <el-table-column
      align="right">

      <template slot-scope="scope">
        <el-button
          size="mini"
          type="success"
          @click.stop="$router.push('/feedback/'+scope.row.id,{params:{feedId:scope.row.id}})">查看</el-button>
        <el-button
          size="mini"
          type="danger"
          @click="MdelFeed(scope.$index, scope.row)">删除</el-button>

      </template>
    </el-table-column>
  </el-table>
</div>
</template>

<script>
import dayjs from 'dayjs'
import { MgetFeed, MdelFeed } from '../../API/manager.js'

export default {
  name: 'MFeedstion',

  data () {
    return {
      tableData: []
    }
  },
  methods: {

    MdelFeed (index, row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await MdelFeed({ id: row.id })

        if (res.status === 0) {
          this.tableData.map((item, i) => {
            if (item.id === row.id) {
              this.tableData.splice(index, 1)
              this.$message({
                showClose: true,
                type: 'success',
                message: '删除成功'
              })
            }
          })
        }
      }).catch(() => {
        this.$message({
          showClose: true,
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    MshowFeed(index,row){

    },
    // 管理获取问答交流
    async  getAllFeed () {
      const { data: res } = await MgetFeed()
      //   console.log(res)
      if (res.status === 0) {
        this.tableData = res.data
        this.tableData.map((item) => {
          if (item.isdown === 1 || item.isdelete === 1) {
            item.isdown = '已下架'
          } else {
            item.isdown = '正常'
          }
          item.pubdata = this.dataFormat(item.pubdata)
        })
      } else {
        this.$message({
          showClose: true,
          type: 'error',
          message: res.message
        })
      }
    },
    dataFormat: (day) => {
      return dayjs(day).format('YYYY-MM-DD HH:mm:ss')
    }
  },

  created () {
    this.getAllFeed()
  }
}
</script>


<style lang="less" scoped>

/deep/ .has-gutter tr td{
    text-align: center;

 }

    /deep/  .el-button+.el-button {
      margin-left: 0 !important;
    }
</style>
